import * as React from 'react';
import {
  Checkbox,
  FormHelperText,
  List,
  ListItemText,
  ListSubheader,
  Skeleton,
  ToggleButton,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useHistory } from 'react-router-dom';
import {
  ProductLine,
  selectAvailableProductLines,
  selectFilter,
  setProductLines,
  toggleSelectedProductLine,
} from './filterSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectStatus } from '../system/systemSlice';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    // maxWidth: 360,
    // @ts-ignore
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    // overflowY: 'scroll',
    // maxHeight: 220,
    height: 'auto',
  },
  listSection: {
    backgroundColor: 'inherit',
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0,
  },
  subHeader: {
    textAlign: 'left',
    paddingLeft: 4,
  },
  listItem: {
    paddingLeft: 0,
  },
  listItemAll: {
    paddingLeft: 0,
    borderBottom: '1px solid lightgray',
    display: 'flex',
  },
  allText: {
    textAlign: 'center',
    paddingBottom: 7.5,
    paddingTop: 7.5,
    color: 'black',
    paddingLeft: 26,
  },
  checkBox: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  selectOneOptionText: {
    color: 'red',
  },
  //   hideSelectOneOptionText: {
  //     // visibility: 'hidden',
  //     background: 'red',
  //   },
  toggleButton: {
    width: '150px',
    margin: '5px',
    fontSize: '9pt',
    textTransform: 'none',
  },
}));

export default function ProductLinesFilterForm() {
  const filter = useAppSelector(selectFilter);
  const availableProductLines = useAppSelector(selectAvailableProductLines);
  const status = useAppSelector(selectStatus);
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [infoTextVisible, setInfoTextVisible] = React.useState(true);

  React.useEffect(() => {
    if (!history.location.pathname.includes('workitems')) {
      setInfoTextVisible(false);
    }
  }, []);

  const onProductLineToggle = React.useCallback(
    (value: ProductLine) => dispatch(toggleSelectedProductLine(value.name)),
    [dispatch],
  );
  const allSelected = React.useMemo(
    () => availableProductLines.every((x) => filter.productLines.includes(x.name)),
    [filter.productLines.length, availableProductLines.length],
  );
  const noneSelected = React.useMemo(
    () => filter.productLines.length === 0,
    [filter.productLines.length],
  );

  React.useEffect(() => {
    if (!infoTextVisible) {
      setInfoTextVisible(true);
    }
  }, [noneSelected]);

  const onSelectAll = React.useCallback(() => {
    if (allSelected) {
      dispatch(setProductLines([]));
    } else {
      dispatch(setProductLines(availableProductLines.map((x) => x.name)));
    }
  }, [dispatch, availableProductLines, allSelected]);

  const isLoadingProductLines = React.useMemo(() => status.productLines === 'busy', [status.productLines]);

  const renderPlaceholder = React.useCallback(
    () => [0, 1, 2, 3, 4, 5].map((value) => {
      const labelId = `togglebutton-list-label-${value}`;
      return (
        <ToggleButton
          key={labelId}
          value={labelId}
          style={{
            backgroundColor: 'lightgray',
            width: '120px',
            margin: '5px',
            height: '35px',
          }}
        >
          <ListItemText
            id={labelId}
            primary={<Skeleton variant="text" width={100} />}
            disableTypography
          />
        </ToggleButton>
      );
    }),
    [],
  );

  const renderFilteredListItems = React.useCallback(
    (filteredProductLines: ProductLine[]) => (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
        }}
      >
        {filteredProductLines.map((value) => {
          const isSelected = filter.productLines.includes(value.name);
          return (
            <ToggleButton
              key={value.name}
              value={isSelected}
              onChange={() => onProductLineToggle(value)}
              style={
                isSelected
                  ? { backgroundColor: '#138943', color: 'white' }
                  : { backgroundColor: 'lightgray' }
              }
              className={classes.toggleButton}
            >
              {value.displayName === '' ? value.name : value.displayName}
            </ToggleButton>
          );
        })}
      </div>
    ),
    [filter.productLines, onProductLineToggle, classes.listItem, classes.checkBox],
  );
  return (
    <div>
      <div style={{ marginBottom: 10 }}>
        <ListSubheader key="all" className={classes.listItemAll}>
          <Checkbox
            className={classes.checkBox}
            edge="start"
            tabIndex={-1}
            disableRipple
            inputProps={{ 'aria-labelledby': 'all' }}
            color="primary"
            checked={allSelected}
            onClick={() => onSelectAll()}
            indeterminate={!allSelected && !noneSelected}
          />
          <Typography id="all" variant="body2" className={classes.allText}>
            All
          </Typography>
        </ListSubheader>
      </div>
      <List className={classes.root} dense subheader={<li />}>
        {availableProductLines.length > 0 && !isLoadingProductLines
          ? renderFilteredListItems(availableProductLines.slice().sort())
          : null}
        {isLoadingProductLines && renderPlaceholder()}
      </List>
      {noneSelected && infoTextVisible ? (
        <FormHelperText className={classes.selectOneOptionText}>
          Select at least one option
        </FormHelperText>
      ) : null}
    </div>
  );
}
