/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Order } from './sorting';
import { RootState } from '../../app/store';
import { WorkItem } from '../workitems/Types';

export interface Sorting {
  order: Order;
  orderBy: keyof WorkItem;
  orderByTechnical: any;
}

export const initialState: Sorting = {
  order: 'asc',
  orderBy: 'productLine',
  orderByTechnical: 'System.TeamProject',
};

export const sortingSlice = createSlice({
  name: 'sorting',
  initialState,
  reducers: {
    setOrder: (state, action: PayloadAction<Order>) => {
      state.order = action.payload;
    },
    setOrderBy: (state, action: PayloadAction<keyof WorkItem>) => {
      state.orderBy = action.payload;
    },
    setOrderByTechnical: (state, action: PayloadAction<any>) => {
      state.orderByTechnical = action.payload;
    },
    clearSort: (state) => {
      state.order = initialState.order;
      state.orderBy = initialState.orderBy;
      state.orderByTechnical = initialState.orderByTechnical;
    },
  },
});

export const {
  setOrder, setOrderBy, setOrderByTechnical, clearSort,
} = sortingSlice.actions;

// selectors
export const selectOrder = (state: RootState) => state.sorting.order;
export const selectOrderBy = (state: RootState) => state.sorting.orderBy;
export const selectOrderByTechnical = (state: RootState) => state.sorting.orderByTechnical;

export default sortingSlice.reducer;
