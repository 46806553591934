import * as React from 'react';
import {
  TableRow, TableCell, Fade, Collapse, Tooltip,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import BugIcon from '@mui/icons-material/BugReport';
import FeatureIcon from '@mui/icons-material/MenuBook';
import { useAppSelector } from '../../app/hooks';
import { selectAdminView } from '../system/systemSlice';
import WorkItemDetail from './WorkItemDetail';
import { WorkItem } from './Types';

interface Props {
  wi: WorkItem;
  displayData: boolean;
  colSpan: number;
}

const useStyles = makeStyles({
  link: {
    textDecorationLine: 'underline',
  },
  tableRow: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  titleCell: {
    minWidth: 420,
  },
  expandedField: {
    paddingBottom: 15,
    paddingTop: 10,
  },
  tableCell: {
    paddingBottom: 0,
    paddingTop: 0,
    borderTop: 'none',
  },
  tagsCell: {
    width: 150,
  },
  padding: {
    paddingTop: 10,
  },
});

export default function WorkItemRow(props: Props) {
  const { wi, displayData, colSpan } = props;
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const adminView = useAppSelector(selectAdminView);

  const getFormattedDate = React.useCallback((timeStamp: number) => {
    const date = new Date(timeStamp);
    return `${date.getFullYear()}-${
      date.getMonth() + 1 > 9 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`
    }-${date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`}`;
  }, []);

  return (
    <>
      <TableRow className={classes.tableRow}>
        <TableCell size="small" width="30px">
          {expanded ? (
            <KeyboardArrowUp
              onClick={() => setExpanded(false)}
              visibility={wi.canExpandTitle || wi.tags.length > 10 ? 'visible' : 'hidden'}
            />
          ) : (
            <KeyboardArrowDown
              onClick={() => setExpanded(true)}
              visibility={wi.canExpandTitle || wi.tags.length > 10 ? 'visible' : 'hidden'}
            />
          )}
        </TableCell>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Fade in={displayData} {...(displayData ? { timeout: 500 } : {})}>
          <TableCell>{wi.productLineDisplayName}</TableCell>
        </Fade>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Fade in={displayData} {...(displayData ? { timeout: 500 } : {})}>
          <TableCell>
            {wi.products.replaceAll(',', ', ')}
          </TableCell>
        </Fade>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Fade in={displayData} {...(displayData ? { timeout: 500 } : {})}>
          <TableCell>{wi.version}</TableCell>
        </Fade>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Fade in={displayData} {...(displayData ? { timeout: 500 } : {})}>
          <TableCell>{wi.component}</TableCell>
        </Fade>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Fade in={displayData} {...(displayData ? { timeout: 500 } : {})}>
          <TableCell width={800} className={classes.titleCell}>
            {wi.shortTitle}
          </TableCell>
        </Fade>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Fade in={displayData} {...(displayData ? { timeout: 500 } : {})}>
          <TableCell width={80}>
            {adminView ? (
              <a href={wi.link} target="_blank" rel="noreferrer noopener" className={classes.link}>
                {wi.id}
              </a>
            ) : (
              wi.id
            )}
          </TableCell>
        </Fade>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Fade in={displayData} {...(displayData ? { timeout: 500 } : {})}>
          <Tooltip
            title={wi.type.toLowerCase() === 'bug' ? 'Bug fixed' : 'Feature added'}
            placement="top"
          >
            <TableCell
              style={{
                paddingLeft: 0,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              {wi.type.toLowerCase() === 'bug' ? <BugIcon /> : <FeatureIcon />}
            </TableCell>
          </Tooltip>
        </Fade>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Fade in={displayData} {...(displayData ? { timeout: 500 } : {})}>
          <TableCell style={{ minWidth: 180 }}>{wi.reportedVersion}</TableCell>
        </Fade>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Fade in={displayData} {...(displayData ? { timeout: 500 } : {})}>
          <TableCell className={classes.tagsCell}>{wi.useTags ? `${wi.tags}` : ''}</TableCell>
        </Fade>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Fade in={displayData} {...(displayData ? { timeout: 500 } : {})}>
          <TableCell style={{ minWidth: 150 }}>
            {wi.date > 949363200000 ? `${getFormattedDate(wi.date)}` : '-'}
          </TableCell>
        </Fade>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Fade in={displayData} {...(displayData ? { timeout: 500 } : {})}>
          <TableCell>{wi.developmentStatus}</TableCell>
        </Fade>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Fade in={displayData} {...(displayData ? { timeout: 500 } : {})}>
          <TableCell>{wi.incidentNumber.replaceAll(',', ', ')}</TableCell>
        </Fade>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Fade in={displayData} {...(displayData ? { timeout: 500 } : {})}>
          <TableCell width="30" />
        </Fade>
      </TableRow>
      <TableRow>
        <TableCell className={classes.tableCell} colSpan={colSpan}>
          <Collapse in={expanded && (wi.canExpandTitle || wi.tags.length > 10)}>
            <div className={classes.expandedField}>
              <WorkItemDetail wi={wi} />
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
