import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { WorkItem } from '../../../features/workitems/Types';

export default async function GeneratePdf(workItems: WorkItem[]) {
  // eslint-disable-next-line new-cap
  const doc = new jsPDF({
    orientation: 'landscape',
    unit: 'mm',
    format: 'a4',
    compress: true,
  });

  // Add header on first page
  doc.addImage(`${process.env.PUBLIC_URL}/backdrop.png`, 'PNG', 5, 5, 287, 30, '');
  doc.setTextColor('#FFFFFF');
  doc.setFontSize(25);
  doc.setFont('Helvetica', '');
  doc.text('Verti       Product Changelogs', 10, 23);
  doc.setFont('Helvetica', 'bold');
  doc.text('GIS', 28, 23);

  // Add table
  autoTable(doc, {
    head: [
      [
        'ProductLine',
        'Product',
        'Version',
        'Component',
        'Title',
        'Id',
        'Type',
        'Reported Version',
        'Tags',
        'Date',
        'Status',
        'Incident Nr.',
      ],
    ],
    body: workItems.map((wi) => [
      wi.productLine,
      wi.products,
      wi.version,
      wi.component,
      wi.shortTitle,
      wi.id.toString(),
      wi.type,
      wi.reportedVersion,
      wi.tags,
      new Date(wi.date).toLocaleDateString().replaceAll('.', '-'),
      wi.developmentStatus,
      wi.incidentNumber.replaceAll(',', ', '),
    ]),
    headStyles: {
      fontStyle: 'bold',
      minCellWidth: 25,
      fillColor: [18, 138, 68],
    },
    columnStyles: {
      0: {
        cellWidth: 18,
      },
      1: {
        cellWidth: 20,
      },
      2: {
        cellWidth: 22,
      },
      3: {
        cellWidth: 25,
      },
      4: {
        cellWidth: 53,
      },
      5: {
        cellWidth: 18,
      },
      6: {
        cellWidth: 13,
      },
      7: {
        cellWidth: 22,
      },
      8: {
        cellWidth: 18,
      },
      9: {
        cellWidth: 18,
      },
      10: {
        cellWidth: 18,
      },
      11: {
        cellWidth: 22,
      },
    },
    margin: 0,
    theme: 'striped',
    startY: 40,
  });

  // Add pagenumber on end of page
  const pageCount = doc.getNumberOfPages();
  doc.setFont('Helvetica', '');
  doc.setFontSize(9);
  doc.setTextColor('#303030');
  for (let i = 0; i < pageCount; i++) {
    doc.setPage(i);
    const currentPage = doc.getCurrentPageInfo();
    doc.text(`Page: ${currentPage.pageNumber} / ${pageCount}`, 265, 202);
  }

  doc.save('VertiGIS_Product_Change_Logs_Download.pdf');
}
