/* eslint-disable no-unused-vars */
import * as React from 'react';
import {
  Alert, Grow, AlertTitle, IconButton,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import {
  selectAvailableProductLines,
  setProductLines as setProductLinesToFilter,
  applyFilter,
  setToDate,
  setFromDate,
  setWorkItemTypeUserStory,
  setWorkItemTypeBug,
  fetchProductLines,
  selectFilter,
} from '../../features/filter/filterSlice';
import { useAppDispatch, useAppSelector, useQuery } from '../../app/hooks';
import {
  selectSearch,
  setComponents,
  setDevStates,
  setGeneralSearchText,
  setId,
  setIncidentNumbers,
  setProductLines as setProductLinesToSearch,
  setReportedVersion,
  setTags,
  setDate,
  setTitle,
  setTypes,
  setVersions,
  setProducts,
} from '../search/searchSlice';
// This component handles the url parameters.
// This component reads the url parameters and sets the filters accordingly.
// In addition, it sets the url parameters depending on which filters have been set.
function ParameterHandler() {
  const [error, setError] = React.useState('');
  const availableProductLines = useAppSelector(selectAvailableProductLines);
  const search = useAppSelector(selectSearch);
  const query = useQuery();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const filter = useAppSelector(selectFilter);
  const productLines = query.get('productLine')?.split(';');
  const products = query.get('product')?.split(';');
  const versions = query.get('version')?.split(';');
  const components = query.get('component')?.split(';');
  const titles = query.get('title');
  const ids = query.get('id');
  const types = query.get('type')?.split(';');
  const reportedVersions = query.get('reportedversion')?.split(';');
  const tags = query.get('tag')?.split(';');
  const releasedates = query.get('releasedate');
  const states = query.get('state')?.split(';');
  const incidentNumbers = query.get('incidentnumber')?.split(';');
  const searchParams = query.get('search');
  const dateFrom = query.get('datefrom');
  const dateTo = query.get('dateto');

  // checks if the url has parameters.
  const HasParameters = () => {
    if (
      productLines == null
      && products == null
      && versions == null
      && components == null
      && titles == null
      && ids == null
      && types == null
      && reportedVersions == null
      && tags == null
      && releasedates == null
      && states == null
      && incidentNumbers == null
      && searchParams == null
      && dateFrom == null
      && dateTo == null
    ) {
      return false;
    }
    return true;
  };

  // checks if the filter has settings
  const HasFilterSettings = () => {
    if (
      filter.productLines.length > 0
      || search.products.length > 0
      || search.versions.length > 0
      || search.components.length > 0
      || search.title.length > 0
      || search.id.length > 0
      || search.types.length > 0
      || search.reportedVersions.length > 0
      || search.tags.length > 0
      || search.date.length > 0
      || search.devStates.length > 0
      || search.incidentNumbers.length > 0
      || search.generalSearchText.length > 0
      || filter.dateRange.from > -1
      || filter.dateRange.to > -1
    ) {
      return true;
    }
    return false;
  };

  // handles a change on the filter to set the parameters.
  React.useEffect(() => {
    if (HasFilterSettings()) {
      const productLineDisplayNames: string[] = availableProductLines
        .filter((productLine) => filter.productLines.includes(productLine.name))
        .map((productLine) => productLine.displayName);

      history.push({
        search: `${new URLSearchParams({
          ...(filter.productLines.length > 0
            && filter.productLines.length < availableProductLines.length && {
            productLine: productLineDisplayNames.join(';'),
          }),
          ...(search.products.length > 0 && { product: search.products.join(';') }),
          ...(search.versions.length > 0 && { version: search.versions.join(';') }),
          ...(search.components.length > 0 && { component: search.components.join(';') }),
          ...(search.title.length > 0 && { title: search.title }),
          ...(search.id.length > 0 && { id: search.id }),
          ...(search.types.length > 0 && { type: search.types.join(';') }),
          ...(search.reportedVersions.length > 0 && {
            reportedversion: search.reportedVersions.join(';'),
          }),
          ...(search.tags.length > 0 && { tag: search.tags.join(';') }),
          ...(search.date.length > 0 && { releasedate: search.date }),
          ...(search.devStates.length > 0 && { state: search.devStates.join(';') }),
          ...(search.incidentNumbers.length > 0 && {
            incidentnumber: search.incidentNumbers.join(';'),
          }),
          ...(search.generalSearchText.length > 0 && { search: search.generalSearchText }),
          ...(filter.dateRange.from > 0 && {
            datefrom: new Date(filter.dateRange.from).toISOString(),
          }),
          ...(filter.dateRange.to > 0 && {
            dateto: new Date(filter.dateRange.to).toISOString(),
          }),
        }).toString()}`,
      });
    }
  }, [search, filter.dateRange, filter.productLines, availableProductLines.length]);

  // handles a parameterized-URL-call
  // This useEffect sets the filter depending on what the parameters are.
  React.useEffect(() => {
    if (HasParameters() && availableProductLines.length > 0) {
      if (productLines !== undefined && productLines.length > 0) {
        const namesOfSelectedProductLines: string[] = [];
        productLines.forEach((productLine) => {
          const selectedProductLine = availableProductLines.find((x) => x.displayName === productLine);
          if (selectedProductLine !== undefined) {
            namesOfSelectedProductLines.push(selectedProductLine.name);
          }
        });
        dispatch(setProductLinesToSearch([...namesOfSelectedProductLines]));
        dispatch(setProductLinesToFilter([...namesOfSelectedProductLines]));
      }
      if (products !== undefined && products.length > 0) {
        dispatch(setProducts([...products]));
      }
      if (versions !== undefined && versions.length > 0) {
        dispatch(setVersions([...versions]));
      }
      if (components !== undefined && components.length > 0) {
        dispatch(setComponents([...components]));
      }
      if (titles !== undefined && titles !== null && titles.length > 0) {
        dispatch(setTitle(titles));
      }
      if (ids !== undefined && ids !== null && ids.length > 0) {
        dispatch(setId(ids));
      }
      if (types !== undefined && types.length > 0) {
        dispatch(setTypes([...types]));
      }
      if (reportedVersions !== undefined && reportedVersions.length > 0) {
        dispatch(setReportedVersion([...reportedVersions]));
      }
      if (tags !== undefined && tags.length > 0) {
        dispatch(setTags([...tags]));
      }
      if (releasedates !== undefined && releasedates) {
        dispatch(setDate(releasedates));
      }
      if (states !== undefined && states.length > 0) {
        dispatch(setDevStates([...states]));
      }
      if (incidentNumbers !== undefined && incidentNumbers.length > 0) {
        dispatch(setIncidentNumbers([...incidentNumbers]));
      }

      if (searchParams != null) dispatch(setGeneralSearchText(searchParams));

      if (productLines === undefined) {
        dispatch(setProductLinesToFilter(availableProductLines.map((p) => p.name)));
      }
      if (dateFrom !== undefined && dateFrom) {
        const dateString = dateFrom;
        const dateObj = new Date(dateString);
        const timestamp = dateObj.getTime();
        dispatch(setFromDate(timestamp));
      }
      if (dateTo !== undefined && dateTo) {
        const dateString = dateTo;
        const dateObj = new Date(dateString);
        const timestamp = dateObj.getTime();
        dispatch(setToDate(timestamp));
      }

      dispatch(setWorkItemTypeBug(true));
      dispatch(setWorkItemTypeUserStory(true));
    }
  }, [availableProductLines.length]);

  React.useEffect(() => {
    dispatch(fetchProductLines());
  }, []);

  React.useEffect(() => {
    dispatch(applyFilter());
  }, [filter.productLines]);
  return (
    <Grow in={error !== ''}>
      <Alert
        variant="outlined"
        severity="error"
        style={{ position: 'fixed', bottom: 70, right: 10 }}
        action={(
          <IconButton onClick={() => setError('')}>
            <Close />
          </IconButton>
        )}
      >
        <AlertTitle>Invalid URL Parameters</AlertTitle>
        {error}
      </Alert>
    </Grow>
  );
}

export default ParameterHandler;
