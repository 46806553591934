/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

export interface Search {
  generalSearchText: string;
  date: string;
  id: string;
  types: string[];
  title: string;
  productLines: string[];
  products: string[];
  components: string[];
  versions: string[];
  reportedVersions: string[];
  tags: string[];
  devStates: string[];
  incidentNumbers: string[];
  showFilter: boolean;
}

export const initialState: Search = {
  generalSearchText: '',
  date: '',
  id: '',
  types: [],
  title: '',
  productLines: [],
  products: [],
  components: [],
  versions: [],
  reportedVersions: [],
  tags: [],
  devStates: [],
  incidentNumbers: [],
  showFilter: false,
};

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setGeneralSearchText: (state, action: PayloadAction<string>) => {
      state.generalSearchText = action.payload;
    },
    resetSearch: (state) => {
      Object.keys(state).forEach((key) => {
        const skey = key as keyof Search;
        if (skey !== 'showFilter') {
          (state as any)[skey] = initialState[skey];
        }
      });
    },
    toggleShowFilter: (state) => {
      state.showFilter = !state.showFilter;
    },
    setDate: (state, action: PayloadAction<string>) => {
      state.date = action.payload;
    },
    setId: (state, action: PayloadAction<string>) => {
      state.id = action.payload;
    },
    setTypes: (state, action: PayloadAction<string[]>) => {
      state.types = action.payload;
    },
    setTitle: (state, action: PayloadAction<string>) => {
      state.title = action.payload;
    },
    setProductLines: (state, action: PayloadAction<string[]>) => {
      state.productLines = action.payload;
    },
    setProducts: (state, action: PayloadAction<string[]>) => {
      state.products = action.payload;
    },
    setComponents: (state, action: PayloadAction<string[]>) => {
      state.components = action.payload;
    },
    setVersions: (state, action: PayloadAction<string[]>) => {
      state.versions = action.payload;
    },
    setReportedVersion: (state, action: PayloadAction<string[]>) => {
      state.reportedVersions = action.payload;
    },
    setTags: (state, action: PayloadAction<string[]>) => {
      state.tags = action.payload;
    },
    setDevStates: (state, action: PayloadAction<string[]>) => {
      state.devStates = action.payload;
    },
    setIncidentNumbers: (state, action: PayloadAction<string[]>) => {
      state.incidentNumbers = action.payload;
    },
  },
});

export const {
  setGeneralSearchText,
  resetSearch,
  toggleShowFilter,
  setDate,
  setId,
  setTypes,
  setTitle,
  setProductLines,
  setProducts,
  setComponents,
  setVersions,
  setReportedVersion,
  setTags,
  setDevStates,
  setIncidentNumbers,
} = searchSlice.actions;

export const selectSearch = (s: RootState) => s.search;

export const selectNoFilterActive = (s: RootState) => {
  const { search } = s;
  return (
    search.generalSearchText === ''
    && search.date === ''
    && search.components.length === 0
    && search.id === ''
    && search.productLines.length === 0
    && search.products.length === 0
    && search.tags.length === 0
    && search.title === ''
    && search.versions.length === 0
    && search.reportedVersions.length === 0
    && search.types.length === 0
    && search.devStates.length === 0
  );
};

export default searchSlice.reducer;
