/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

export type Status = 'idle' | 'busy';
export interface SystemState {
  status: {
    workItems: Status;
    productLines: Status;
  };
  adminView: boolean;
}

export const initialState: SystemState = {
  status: {
    workItems: 'idle',
    productLines: 'idle',
  },
  adminView: false,
};

export const systemSlice = createSlice({
  name: 'system',
  initialState,
  reducers: {
    setWorkitemStatus: (state, action: PayloadAction<Status>) => {
      state.status.workItems = action.payload;
    },
    setProductLineStatus: (state, action: PayloadAction<Status>) => {
      state.status.productLines = action.payload;
    },
    toggleAdminView: (state) => {
      state.adminView = !state.adminView;
    },
  },
});

export const {
  setWorkitemStatus, setProductLineStatus, toggleAdminView,
} = systemSlice.actions;

export const selectStatus = (state: RootState) => state.system.status;
export const selectAdminView = (state: RootState) => state.system.adminView;

export default systemSlice.reducer;
