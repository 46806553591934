/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { cloneDeep } from 'lodash';
import TypeParser from './TypeParser';
import { RootState } from '../../app/store';
import { setProductLineStatus } from '../system/systemSlice';
import { WorkItem } from '../workitems/Types';
import { Search } from '../search/searchSlice';

export interface ProductLine {
    name: string;
    displayName: string;
}

export interface FilterState {
  productLines: string[];
  conditions: string[];
  workItemType: {
    bug: boolean;
    userStory: boolean;
  };
  valid: boolean;
  dateRange: {
    from: number;
    to: number;
  };
}

export interface Filter {
  appliedFilter: FilterState;
  currentFilter: FilterState;
  productLinesIsSelected: boolean;
  availableProductLines: ProductLine[];
}

const initialFilterState: FilterState = {
  productLines: [],
  conditions: [],
  workItemType: {
    bug: true,
    userStory: true,
  },
  valid: true,
  dateRange: {
    from: -1,
    to: -1,
  },
};

export const initialState: Filter = {
  appliedFilter: initialFilterState,
  currentFilter: cloneDeep(initialFilterState),
  availableProductLines: [],
  productLinesIsSelected: true,
};
export const filterWorkItems = (workItems: WorkItem[], search: Search) => {
  const checkStringInArray = (array: string[], searchString: string, seperator: string = '') => {
    if (array.length === 0) {
      return true;
    }
    if (seperator !== '') {
      const searchTerms = searchString.split(seperator).map((term) => term.trim());
      return searchTerms.some(
        (term) => array.map((string) => string.trim()).includes(term.trim()) && term !== '-',
      );
    }
    return array.some((item) => item === searchString && searchString !== '-');
  };

  const getFormattedDate = (timeStamp: number) => {
    const date = new Date(timeStamp);
    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date
      .getDate()
      .toString()
      .padStart(2, '0')}`;
  };

  const getSearchStringFromWorkItem = (workItem: WorkItem) => {
    const formattedDate = getFormattedDate(workItem.date);
    return `${formattedDate}${workItem.component}${workItem.id}${workItem.productLine}${workItem.products}${workItem.tags}${workItem.title}${workItem.type}${workItem.version}${workItem.reportedVersion}${workItem.developmentStatus}${workItem.incidentNumber}`;
  };

  return workItems.filter(
    (wi) => wi.title.toLowerCase().includes(search.title.toLowerCase().trim())
      && wi.id.toString().includes(search.id.toLowerCase().trim())
      && checkStringInArray(search.types, TypeParser.parseType(wi.type))
      && checkStringInArray(search.productLines, wi.productLine)
      && checkStringInArray(search.products, wi.products, ',')
      && checkStringInArray(search.components, wi.component)
      && checkStringInArray(search.versions, wi.version)
      && checkStringInArray(search.reportedVersions, wi.reportedVersion)
      && checkStringInArray(search.tags, wi.tags, ';')
      && getFormattedDate(wi.date).toLowerCase().includes(search.date.toLowerCase().trim())
      && checkStringInArray(search.devStates, wi.developmentStatus)
      && checkStringInArray(search.incidentNumbers, wi.incidentNumber, ',')
      && search.generalSearchText
        .toLowerCase()
        .split(',')
        .some((searchTerm) => getSearchStringFromWorkItem(wi).toLowerCase().includes(searchTerm.trim())),
  );
};

export const fetchProductLines = createAsyncThunk('filter/fetchProjects', async (args, thunkApi) => {
  const state = thunkApi.getState() as RootState;
  if (state.filter.availableProductLines.length <= 0) {
    thunkApi.dispatch(setProductLineStatus('busy'));
  }
  const response = await axios.get<ProductLine[]>('workitem/projects');
  thunkApi.dispatch(setProductLineStatus('idle'));
  return response.data.sort((a, b) => a.displayName.localeCompare(b.displayName));
});

export const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    setWorkItemTypeBug: (state, action: PayloadAction<boolean>) => {
      state.currentFilter.workItemType.bug = action.payload;
    },
    setWorkItemTypeUserStory: (state, action: PayloadAction<boolean>) => {
      state.currentFilter.workItemType.userStory = action.payload;
    },
    setValid: (state, action: PayloadAction<boolean>) => {
      state.currentFilter.valid = action.payload;
    },
    setProductLines: (state, action: PayloadAction<string[]>) => {
      state.currentFilter.productLines = action.payload;
    },
    toggleSelectedProductLine: (state, action: PayloadAction<string>) => {
      if (!state.currentFilter.productLines.includes(action.payload)) {
        state.currentFilter.productLines.push(action.payload);
      } else {
        const index = state.currentFilter.productLines.findIndex((x) => x === action.payload);
        if (index !== -1) {
          state.currentFilter.productLines.splice(index, 1);
        }
      }
    },
    applyFilter: (state) => {
      state.appliedFilter = cloneDeep(state.currentFilter);
    },
    resetFilter: (state) => {
      state.currentFilter = cloneDeep(initialFilterState);
    },
    validate: (state) => {
      const productLinesValid = (state.productLinesIsSelected && state.currentFilter.productLines.length > 0);

      // WorkItemType validation
      const workItemTypeValid = [state.currentFilter.workItemType.bug, state.currentFilter.workItemType.userStory].filter(
        (x) => x,
      ).length !== 0;

      // Date validation
      const fromDateValid = state.currentFilter.dateRange.from !== -2;
      const toDateValid = state.currentFilter.dateRange.to !== -2;
      const numberOfDateSelected = [
        state.currentFilter.dateRange.from,
        state.currentFilter.dateRange.to,
      ].filter((x) => x !== -1).length;
      const toDateGreaterThanFromDate = state.currentFilter.dateRange.from < state.currentFilter.dateRange.to
        || numberOfDateSelected !== 2;

      // Final validation
      state.currentFilter.valid = workItemTypeValid
        && productLinesValid
        && fromDateValid
        && toDateValid
        && toDateGreaterThanFromDate;
    },
    setFromDate: (state, action: PayloadAction<number>) => {
      state.currentFilter.dateRange.from = action.payload;
    },
    setToDate: (state, action: PayloadAction<number>) => {
      state.currentFilter.dateRange.to = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProductLines.fulfilled, (state, action) => {
      state.availableProductLines = action.payload;
    });
  },
});

export const selectWorkItemType = (state: RootState) => state.filter.currentFilter.workItemType;
export const selectFilter = (state: RootState) => state.filter.currentFilter;
export const selectAppliedFilter = (state: RootState) => state.filter.appliedFilter;
export const selectAvailableProductLines = (state: RootState) => state.filter.availableProductLines;
export const selectProductLinesIsSelected = (state: RootState) => state.filter.productLinesIsSelected;
export const {
  setWorkItemTypeBug,
  setWorkItemTypeUserStory,
  setValid,
  setProductLines,
  validate,
  toggleSelectedProductLine,
  applyFilter,
  resetFilter,
  setFromDate,
  setToDate,
} = filterSlice.actions;
export default filterSlice.reducer;
